import React from "react";
import "./index.css";
import logo from "../../../assets/images/logo.png";
import { useAppSelector } from "../../../hooks/appSelector";
import { step1Save } from "../../../store/slices/register/register.slice";
import { useDispatch } from "react-redux";
import { RegisterType } from "../../../types/RegisterType";
import { Formik } from "formik";
import registerValidation from "../../../utils/validations/registerValidation";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { InputComponent } from "../../../components";
import { useNavigate } from "react-router-dom";

const RegisterPage: React.FC = () => {
  const register = useAppSelector((state) => state.register.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="mx-auto max-w-sm py-4 px-6 lg:px-8 container">
      <img src={logo} alt="WMusic" className="img-logo" />
      <h1 className="text-xl font-bold text-white mb-7">
        Cadastre-se no Festival W. Music
      </h1>      

      <Formik
        enableReinitialize
        validationSchema={registerValidation}
        initialValues={register as RegisterType}
        onSubmit={(values, actions) => {
          dispatch(step1Save(values));
          navigate('/passo-dois')
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="formwm">
            <InputComponent
              type="text"
              placeholder="Nome Completo"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              name="name"
              error={errors.name}
              touched={touched.name}
            />

            <InputComponent
              pattern="\d*"
              type="tel"
              mask={"(00) 00000-0000"}
              placeholder="WhatsApp"
              value={values.whatsApp}
              onChange={handleChange}
              onBlur={handleBlur}
              name="whatsApp"
              error={errors.whatsApp}
              touched={touched.whatsApp}
            />

            <InputComponent
              pattern="\d*"
              type="tel"
              mask={"(00) 00000-0000"}
              placeholder="Confirme seu WhatsApp"
              value={values.whatsAppConfirm}
              onChange={handleChange}
              onBlur={handleBlur}
              name="whatsAppConfirm"
              error={errors.whatsAppConfirm}
              touched={touched.whatsAppConfirm}
            />

            <InputComponent
              type="text"
              pattern="\d*"
              placeholder="CPF"
              value={values.cpf}
              onChange={handleChange}
              onBlur={handleBlur}
              name="cpf"
              error={errors.cpf}
              touched={touched.whatsApp}
            />

            <InputComponent
              type="text"
              placeholder="Endereço Completo"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              name="address"
              error={errors.address}
              touched={touched.address}
            />

            <button
              type="submit"
              className="button-primary flex w-full justify-center rounded-md bg-indigo-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
            >
              Continuar
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterPage;
