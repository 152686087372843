import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/appSelector";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import Contract from "../../../components/contract";
import { saveContract } from "../../../store/slices/register/register.slice";
import { pdf, usePDF } from "@react-pdf/renderer";
import React, { useEffect, useMemo } from "react";


const RegisterStep2Page = () => {
  const register = useAppSelector((state) => state.register.data);
  const navigate = useNavigate();


  const onClick = () => {
    navigate("/passo-tres");
  };

  return (
    <div className="mx-auto max-w-sm py-4 px-6 sm:py-10 lg:px-8">
      {/* <img src={logo} alt="WMusic" className="img-logo" /> */}

      <Contract register={register} />

      <button
        onClick={onClick}
        type="submit"
        className="button-primary flex w-full justify-center rounded-md bg-indigo-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
      >
        Assinar Contrato
      </button>
    </div>
  );
};

export default RegisterStep2Page;
