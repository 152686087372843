import { createAsyncThunk } from "@reduxjs/toolkit";
import { RegisterType } from "../../types/RegisterType";
import api from "../api";
import axios from "axios";

export const postRegister = createAsyncThunk(
  "POST_REGISTER",
  async (body: RegisterType, { rejectWithValue }) => {
    const response = await api.post("register", {
      name: body.name,
      whatsApp: body.whatsApp,
      address: body.address,
      cpf: body.cpf,
      contractPdf: body.contractPdf,
    });
    
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response.data.message);
    }

    const result = response.data as RegisterType;

    return result;
  }
);
