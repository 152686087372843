import axios from "axios";

const api = axios.create({
  baseURL: "https://api.gravadoragospel.com.br/app/api/",
  // baseURL: "https://localhost:3043/api/",
  headers: {
    // Accept: "application/json",
    "Content-Type": "application/json",
    // Authorization:
    //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyIiwiYnVzaW5lc3NJZCI6IjEiLCJwcm9maWxlUm9sZSI6IkFkbWluaXN0cmFkb3IiLCJzdWIiOiJicnVub0BpaGVscGNoYXQuY29tIiwianRpIjoiMTRmNzg4ZmEtZmI1Mi00NGEwLWJlMzEtNGI0ZGM2ODIwYzQzIiwiaWF0IjoxNjc2NTUxNTk1LCJyb2wiOiJhcGlfYWNjZXNzIiwiaWQiOiIyIiwibmJmIjoxNjc2NTUxNTk1LCJleHAiOjE2ODY5MTk1OTUsImlzcyI6ImNvbXphZGEuY29tLmJyIiwiYXVkIjoiQ29temFkYUF1ZGllbmNpZVNseiJ9.0yf3uXLYKnp-ToJmGgMhDoFgtFymD9j9gmVM9s0Y2Ek",
  },
});

export default api;