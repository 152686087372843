import ContractDownload from "../pages/ContractDownload";
import RegisterPage from "../pages/Register/RegisterPage";
import RegisterStep2Page from "../pages/Register/RegisterStep2Page";
import RegisterStep3Page from "../pages/Register/RegisterStep3Page";
import RegisterStep4Page from "../pages/Register/RegisterStep4Page";
import { RouterType } from "../types/RouterType";

const RoutesData: RouterType[] = [
    {
      path: "/",
      element: <RegisterPage />,
      title: "Configurações",
    },
    {
      path: "/passo-dois",
      element: <RegisterStep2Page />,
      title: "Passo 2",
    },
    {
      path: "/passo-tres",
      element: <RegisterStep3Page />,
      title: "Passo 3",
    },
    {
      path: "/passo-quatro",
      element: <RegisterStep4Page />,
      title: "Passo 4",
    },
    {
      path: "/download-contrato",
      element: <ContractDownload />,
      title: "Contrato",
    },
];

export default RoutesData;