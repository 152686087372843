import styled from "styled-components";

const Div = styled.div`
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(125 123 134 / 82%);
  top: 0;
  left: 0;
`;

export { Div };
