import { number, object, string, ref } from "yup";

const registerValidation = object({
    name: string().required("Informe seu nome completo"),
    whatsApp: string().required("Informe seu WhatsApp"),
    whatsAppConfirm: string().required("Confirme seu WhatsApp").oneOf([ref('whatsApp')], 'Informe o mesmo WhatsApp acima'),
    cpf: string().required("Informe seu CPF"),
    address: string().required("Informe seu endereço"),
});

export default registerValidation;