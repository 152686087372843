import logo from "../../../assets/images/logo.png";
import Contract from "../../../components/contract";
import { useAppSelector } from "../../../hooks/appSelector";
import { IconSucess } from "./style";
import QRCode from "react-qr-code";
import {
  BlobProvider,
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  View,
  usePDF,
  Document,
} from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import React, { useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { Div } from "../../ContractDownload/style";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const RegisterStep4Page: React.FC = () => {
  const register = useAppSelector((state) => state.register.data);
  const navigate = useNavigate();
  const ref = React.createRef<HTMLDivElement>();


  const downloadPdfDocument = () => {
    const element = document.getElementById("jsx-template") as HTMLElement;
    element.style.display = "block";

    html2canvas(element as HTMLElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", [297, 210]);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      var base = pdf.output("datauristring");
      pdf.save("wm-contrato.pdf");
      element.style.display = "none";
    });
  };

  return (
    <div className="mx-auto max-w-sm py-4 px-6 sm:py-10 lg:px-8">
      <img src={logo} alt="WMusic" className="img-logo" />
      <h1 className="text-xl font-bold text-white mb-7 text-center">
        <IconSucess />
        Cadastro Concluído
      </h1>

      <div
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 200,
          width: "100%",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={`https://gravadoragospel.com.br/register/${register?.id}`}
          viewBox={`0 0 256 256`}
        />

        <button
          onClick={downloadPdfDocument}
          type="submit"
          className="flex w-full justify-center rounded-md bg-indigo-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
        >
          Salvar Contrato
        </button>

        <Div ref={ref} id={"jsx-template"}>
          <Contract register={register} />
        </Div>
      </div>
    </div>
  );
};

export default RegisterStep4Page;
