import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { RouterType } from "../types/RouterType";
import RoutesData from "./RoutesData";
import { useAppSelector } from "../hooks/appSelector";

const RoutesApp = () => {
  const register = useAppSelector((state) => state.register.data);
  const location = useLocation();
  
  if (location.pathname !== '/' && (!register || register.name === "")) {
    return <Navigate to="/" />;
  }

  const pageRoutes = RoutesData.map(({ path, title, element }: RouterType) => {
    return <Route key={title} path={`/${path}`} element={element} />;
  });

  return (
    <Routes>
      {pageRoutes}
    </Routes>
  );
};

export default RoutesApp;
