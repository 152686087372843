import {
  Document,
  Page,
  View,
  Text,
  PDFViewer,
  pdf,
} from "@react-pdf/renderer";
import { useAppSelector } from "../../hooks/appSelector";
import { stylesPdf } from "./style";
import { RegisterType } from "../../types/RegisterType";

const Contract = ({...props}) => {

  return (
    // <PDFViewer style={stylesPdf.document}>
    <Document>
      <Page size="A4" style={stylesPdf.page}>
        <View style={stylesPdf.section}>
          <Text>
            <center>
              <strong>Regulamento e Contrato Festival W.Music.</strong>
            </center>{" "}
            <br />
            Pelo presente contrato particular de serviços artísticos e na melhor
            forma de direito, as partes signatárias, um lado, como contratado,
            Wellington Corrêa da Silveira, brasileiro , Casado, Maior , Diretor
            Artístico, portador do Rg n.º32.580.803–X, inscrito no cpf sob n.º
            295094968-12, residente e domiciliado na cidade de S.J.do Rio Preto
            - SP , na Rua Siqueira Campos 3917 e de outro lado, As Partes acima
            Indentificadas tem, entre si justo e acertado o Presente Contrato de
            Prestação de Serviços, que Regerá pelas clausulas e pelas condições
            de preços,formas e termos de pagamento descritas no presente.
            <br />
            <br />
            <strong>
              Cláusula 1.ª – O Festival acontecerá em duas Etapas que são:{" "}
            </strong>
            inscrição e preparação com aulas de canto online, e a 2* segunda a
            fase do Festival, assim que finalizar as inscrições,{" "}
            <strong>
              a Data e o Local do Festival será colocada no QRCODE no maximo em
              até 30 dias antes da data do Festival
            </strong>
            , Os Finalistas cantarão entre 1:30 e no Máximo até 2:00 (dois
            minutos) no play Back de qualquer musica Gospel, ou a Música que a
            W.Music indicar, será de escolha própria em um dos dias da Final
            para os Jurados. Será avaliado{" "}
            <strong>Timbre, Afinação e Interpretação</strong> (o play back é de
            Responsabilidade do Finalista ) Se por força maior for alterado a
            data ou o local da grande final será avisado no prazo minimo de 7
            dias. Ou algum Jurado por motivo de doença será substituído por
            outro Jurado. (caso haja empate técnico, os jurados poderão pedir
            para os candidatos escolhidos cantarem novamente qualquer canção
            Gospel a capela 30 segundos).
            <br />
            <br />
            <strong>
              Os jurados escolherão os Finalistas conforme o horario agendado
            </strong>
            que se Reapresentará no mesmo dia, as Melhores vozes, Escolhida
            pelos Jurados, cantará Novamente, e a voz vencedora desse dia
            Gravará um 1 Single no Estúdio W.Music, Mixado e Masterizado, está
            incluso Clipe e a Musica nas Plataformas Digitais, (O prêmio ao
            Finalista, não será permitida sua troca por dinheiro ou qualquer
            outro produto ou serviço.)
            <br />
            <br />
            <strong>Cláusula 2.a</strong> - O contratante obriga-se a aceitar os
            serviços prestados pelo contratado na forma prevista na cláusula
            anterior, pegará 25 Convites á R$38,00 Reais cada o que montam O
            valor total de R$950,00 cada Convite dará direito á pessoa ter o
            endereço do evento que está no QRCODE , o valor dos convites, será
            Depositado até o <strong>dia 08 de Fevereiro 2024</strong> Banco Bradesco
            agência 0596 Conta 8649-5 Wellington Corrêa da Silveira - ME{" "}
            <strong>PIX - CNPJ 226340790001 45</strong>
            haverá um sorteio para quem adquiriu os convites, um Pix no valor de R$ 3.000,00
            (Três mil reais) será divulgado a data e transmitido no YouTube Canal Gravadora Gospel.
            <br />
            <br />
            <strong>
              Após a assinatura desse contrato fica impossibilitado o
              contratante ou o contratado de cancelamento do evento ou a
              Desistência do Finalista, acarretando a multa para ambos no valor
              de R$ 950.00.
            </strong>
            <br />
            <br />
            Caso o Finalista desiste de cantar na fase final ou não consiga
            vender todos os Convites, o Valor da Multa é R$ 950,00 não poderá
            devolver-los, é obrigatório o pagamento de todos os convites no
            Valor de R$ 950.00, por já ter encerrado as inscrições não podendo
            colocar ninguém na vaga, ambos Não poderão Desistir, O valor total
            deverá ser pago até o dia mencionado sob pena de o (a)contratante
            não participar da final do festival declarando-se inadimplente,
            dando ensejo ao contratado de executar o contrato.
            <br />
            <strong>Cláusula 3.a</strong> - A formalização do presente contrato
            particular está condicionado ao seu completo e regular preenchimento
            com assinatura obrigatória do contratante e contratado.
            <br />
            <strong>Cláusula 4.a</strong> - Fica eleito o foro da Comarca de São
            José do Rio Preto-SP para dirimir eventuais dúvidas ou controvérsias
            oriundas deste instrumento, renunciando-se a qualquer outro, por
            mais privilegiados que seja. Assim, por estarem justas, contratadas
            e concordes com as cláusulas e condições insertas neste documento
            particular, as partes o assinam em duas vias de igual teor e forma,
            para que produza seus jurídicos e legais efeitos.
            <br />
            
            <br />
            <center>São José do Rio Preto, 15 de Dezembro de 2023</center>
            <br />
            <strong>Nome Completo:</strong> {props.register?.name} <br />
            <strong>Endereço:</strong> {props.register?.address} <br />
            <strong>Whatsapp:</strong> {props.register?.whatsApp} <br />
            Vencimento: Valor: ***950,00 *** Aos Oito de Fevereiro de Dois Mil e
            vinte e Quatro pagarei por esta única via de N O T A P R O M I S S Ó R
            I A OU À SUA ORDEM A QUANTIA DE:
            <br />
            *** Novecentos e Cinquenta REAIS **EM MOEDA CORRENTE NACIONAL.
            <br />
            <br />
            <strong>CPF:</strong> {props.register?.cpf}
            <br />
            <strong>Assinatura:</strong>{" "}
            {props.register?.sign ? (
              <img src={props.register?.sign} style={stylesPdf.sign} />
            ) : (
              "____________________"
            )}
          </Text>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default Contract;
