import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from "../../../assets/images/logo.png";
import SignatureCanvas from "react-signature-canvas";
import { Sign } from "./style";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useAlert } from "react-alert";
import { useAppSelector } from "../../../hooks/appSelector";
import { useDispatch } from "react-redux";
import {
  saveContract,
  step3Save,
} from "../../../store/slices/register/register.slice";
import { postRegister } from "../../../services/register";
import { RegisterType } from "../../../types/RegisterType";
import { AppDispatch } from "../../../store";
import ContractDownload from "../../ContractDownload";
import Contract from "../../../components/contract";
import { Div } from "../../ContractDownload/style";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loading from "../../../components/ui/Loading";

const RegisterStep3Page: React.FC = () => {
  const register = useAppSelector((state) => state.register.data);
  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const alert = useAlert();
  let sigCanvas: SignatureCanvas | null;
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    
    if (!register?.contractPdf?.includes("data:application/pdf;") &&
    register?.sign?.includes("data:image/png;base64")) {
      pdfToBase64().then((base64) => {
        dispatch(saveContract(base64));
      });
    }

    if (
      register?.contractPdf?.includes("data:application/pdf;") &&
      register?.sign?.includes("data:image/png;base64")
    ) {
      dispatch(postRegister(register as RegisterType));
      setLoading(false);
      navigate("/passo-quatro");
    }
  }, [register]);

  const onClick = async () => {
    if (!accept) {
      alert.error("Você precisa aceitar os termos para continuar");
    } else {
      if (sigCanvas?.isEmpty()) {
        alert.error("Você precisa assinar o contrato para continuar");
      } else {
        setLoading(true);
        dispatch(step3Save(sigCanvas?.getCanvas().toDataURL()));
      }
    }
  };

  const pdfToBase64 = () => {
    const promise = new Promise((resolve, reject) => {
      const element = document.getElementById("jsx-template") as HTMLElement;
      element.style.display = "block";

      html2canvas(element as HTMLElement).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", [297, 210]);
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        var base = pdf.output("dataurlstring");
        // pdf.save("download.pdf");
        element.style.display = "none";
        resolve(base);
      });
    });

    return promise;
  };

  const onClear = () => {
    sigCanvas?.clear();
  };

  return (
    <div className="mx-auto max-w-sm py-4 px-6 sm:py-10 lg:px-8">
      
      {loading && <Loading />}

      {/* <img src={logo} alt="WMusic" className="img-logo" /> */}
      <h1 className="text-xl font-bold text-white mb-7">
        Assine no quadro branco abaixo:
      </h1>

      <button
        onClick={onClear}
        title="Limpar"
        type="button"
        className="float-right mb-2 rounded-full bg-slate-50 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <TrashIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
      </button>
      <Sign>
        <SignatureCanvas
          penColor="blue"
          backgroundColor="white"
          ref={(ref) => {
            sigCanvas = ref;
          }}
        />
      </Sign>

      <fieldset>
        <div className="relative flex items-start mt-5">
          <div className="flex h-6 items-center">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              checked={accept}
              onChange={() => setAccept((prev) => !prev)}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
          </div>
          <div className="text-sm leading-6">
            <label htmlFor="comments" className="text-slate-300 mt-2 text-xs">
              Ao assinar você estará de acordo com todos os termos contidos no
              contrato
            </label>
          </div>
        </div>
      </fieldset>
      <button
        onClick={onClick}
        type="submit"
        className="button-primary flex w-full justify-center rounded-md bg-indigo-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
      >
        Assinar
      </button>

      <Div ref={ref} id={"jsx-template"}>
        <Contract register={register} />
      </Div>
    </div>
  );
};

export default RegisterStep3Page;
