import { StyleSheet } from '@react-pdf/renderer';
import styled from 'styled-components';

// const Content = styled.div`
//   max-height: 55vh;
//   overflow-y: auto;
//   border-radius: 4px;
// `;

const stylesPdf = StyleSheet.create({
  document: {
    // height: '55vh',
    // width: '100%',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    maxHeight: '45vh',
    overflowY: 'auto',
    padding: 10,
    borderRadius: 4

  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontSize: 12,
    textAlign: 'justify',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  sign: {
    height: '75px',
  }
});



export { stylesPdf };