import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { IMaskInput } from 'react-imask';

interface Props {
  value?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  touched?: boolean;
  mask?: any;
  pattern?: any;
}

const InputComponent: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  error,
  touched,
  name,
  placeholder,
  type,
  mask,
  pattern
}) => {
  return (
    <div>
      <div className="relative mt-2 rounded-md shadow-sm">
        <IMaskInput
          pattern={pattern}
          mask={mask}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          aria-invalid="true"
          aria-describedby="email-error"
          className={`bg-opacity-70 block w-full rounded-md border-0 py-2 px-3.5 text-white shadow-sm ring-gray-300 placeholder:text-gray-100 sm:text-sm sm:leading-6 mt-2 ${
            error && touched && error
              ? "text-red-900 ring-1 ring-inset ring-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500"
              : ""
          }`}
        />
        {error && touched && error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-600"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <p className="mt-1 mb-3 text-sm text-red-600 text-left" id="email-error">
        {error && touched && error}
      </p>
    </div>
  );
};

export default InputComponent;
