import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, useNavigate } from "react-router-dom";
import RoutesApp from "./routes/RoutesApp";
import { useAppSelector } from "./hooks/appSelector";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { PDFViewer } from "@react-pdf/renderer";
import { Provider } from "react-redux";
import registerSlice from "./store/slices/register/register.slice";
import { configureStore } from "@reduxjs/toolkit";
import { Analytics } from "@vercel/analytics/react";

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <div className="App">
        <main>
          <BrowserRouter>
            <RoutesApp />
          </BrowserRouter>
        </main>
      </div>
      <Analytics />
    </AlertProvider>
  );
}

export default App;
