import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "../../../types/InitialState";
import { RegisterType } from "../../../types/RegisterType";
import { postRegister } from "../../../services/register";

const initialState: InitialState<RegisterType> = {
  loading: false,
  error: null,
  data: {
    name: "",
    whatsApp: "",
    cpf: "",
    address: "",
    sign: "",
    contractPdf: "",
    id: "",
  },
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    step1Save: (state, action: PayloadAction<RegisterType>) => {
      state.data = action.payload;
    },
    step3Save: (state, action: PayloadAction<any>) => {
      (state.data as any).sign = action.payload;
    },
    saveContract: (state, action: PayloadAction<any>) => {
      (state.data as any).contractPdf = action.payload;
    },
    registerRequest: (state) => {
      state.loading = true;
    },
    registerSuccess: (state, action: PayloadAction<RegisterType>) => {
      state.loading = false;
      state.data = action.payload;
    },
    registerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postRegister.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(postRegister.fulfilled, (state, action) => {
        (state.data as any).id = (action.payload as RegisterType).id;
        state.loading = false;
      })
      .addCase(postRegister.rejected, (state, action) => {
        state.loading = false;
        // state.error["has"] = true;
        // state.error["message"] = action.error.message;
      });
  },
});

export const {
  registerRequest,
  registerSuccess,
  registerFailure,
  step1Save,
  step3Save,
  saveContract,
} = registerSlice.actions;
export default registerSlice.reducer;
