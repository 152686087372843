import styled from "styled-components";

const Sign = styled.div`
    canvas {
        width: 100%;
        height: 200px;
        border-radius: 4px;
    }
`;

export { Sign };
