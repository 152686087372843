import { Spinner } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";
import { Div } from "./style";

const Loading = () => {
  return (
    <Div>
      <Spinner
        text={"Carregando..."}
        color={"#000"}
        center={true}
        width={"150px"}
        height={"150px"}
      />
    </Div>
  );
};

export default Loading;
