import React from "react";
import Contract from "../../components/contract";
import { useAppSelector } from "../../hooks/appSelector";
import { Div } from "./style";
import { Preview, print } from "react-html2pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ContractDownload = () => {
  const register = useAppSelector((state) => state.register.data);
  const ref = React.createRef<HTMLDivElement>();

//   var blob = new Blob(document.getElementById("jsx-template"), {type: "text/plain"});
// console.log(blob);

  const generatePDF = () => {
    // Choose the element that our invoice is rendered in.
    const element = document.getElementById("nodeToRenderAsPDF");

    // clone the element
    var clonedElement = element?.cloneNode(true);

    // change display of cloned element
    // $(clonedElement).css("display", "block");

    // Choose the clonedElement and save the PDF for our user.
    // html2pdf(clonedElement);

    // remove cloned element
    // clonedElement.remove();
  };

  const downloadPdfDocument = () => {
    const element = document.getElementById("jsx-template") as HTMLElement;
    element.style.display = "block";

    html2canvas(element as HTMLElement)
      .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p','mm',[297, 210]);
          const imgProps= pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
          var base = pdf.output('datauristring');
          pdf.save("download.pdf");
          element.style.display = "none";
      })
  }

  return (
    <>
      {/* <ReactToPdf targetRef={ref} filename="div-blue.pdf">
        {(pdf: any) => (
          <button
            className="flex w-full justify-center rounded-md bg-indigo-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
            // onClick={pdf.toPdf}
            onClick={generatePDF}
          >
            Salvar contrato
          </button>
        )}
      </ReactToPdf> */}
      {/* <button onClick={() => print("a", "jsx-template")}> print</button> */}
      <button
        className="flex w-full justify-center rounded-md bg-indigo-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
        // onClick={pdf.toPdf}
        onClick={() => print("contrato-wmusic", "jsx-template")}
      >
        Salvar contrato
      </button>

      {/* <Preview id={"jsx-template"}> */}
      
      <button onClick={downloadPdfDocument}>Download Pdf</button>
      <Div ref={ref} id={"jsx-template"}>
          <Contract register={register} />
        </Div>
    
      {/* </Preview> */}
    </>
  );
};

export default ContractDownload;
