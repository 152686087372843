import styled from "styled-components";

const Div = styled.div`
  width: 21cm;
  height: 100%;
  // display: inline-block;
  display: none;
  margin: 0 auto;
  margin-top: 80px;

  main {
    display: inline-grid;
    background-color: #fff !important;
  }

  page {
    height: 100%;
    max-height: 100% !important;
  }
`;

export { Div };
